import { takeEvery, all, put, call } from 'redux-saga/effects'
import getMenuData from 'services/menu.service'
import actions from '../user/actions'

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, GET_DATA), // run once on app load to fetch menu data
    GET_DATA(),
  ])
}
