import { put, takeLatest } from 'redux-saga/effects'

import * as actions from './actions'

import data from './api'

function* init() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      data,
    },
  })
}

function* update(rowData) {
  data = [...data, rowData.payload]
  yield put({
    type: actions.SET_STATE,
    payload: {
      data,
    },
  })
}

export default function* sagas() {
  yield [takeLatest(actions.INIT, init), takeLatest(actions.UPDATE, update)]
}
