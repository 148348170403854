// import _ from 'lodash'

export default async function getMenuData() {
  const userStr = localStorage.getItem('user')
  const userData = JSON.parse(userStr)
  if (userData) {
    const { child = [], menu = [], module = [] } = userData

    const routeData = module.map(module => {
      const parent = menu
        .filter(menuItem => menuItem.moduleid === module.moduleid)
        .map(parentItem => {
          const children = child
            .filter(item => item.parentid === parentItem.parentid)
            .map(item => ({
              ...item,
              key: `${parentItem.parentid}-${item.childname}`,
              title: item.childname,
              url: item?.childurl,
            }))
          const returnData = {
            ...parentItem,
            key: `${parentItem.moduleid}-${parentItem.parentname}`,
            title: parentItem.parentname,
          }

          if (children?.length) {
            returnData.children = children
          } else {
            returnData.url = parentItem?.parenturl
          }

          return returnData
        })
      return {
        ...module,
        children: parent,
        category: true,
        title: module.modulename,
        key: module.modulename,
        icon: module.classname,
      }
    })

    return routeData
  } else {
    return []
  }
}

// export default async function getMenuData() {
//   return [
//     {
//       category: true,
//       title: 'Dashboards',
//     },
//     {
//       title: 'Finance',
//       key: 'financedashboards',
//       icon: 'fe fe-activity',
//       // roles: ['employee'], // set user roles with access to this route

//       children: [
//         {
//           title: 'Profitability Analysis',
//           key: 'profitabilityanalysis',
//           url: '/dashboard/profitability',
//         },
//       ],
//     },
//     {
//       title: 'Operations',
//       key: 'operationsdashboards',
//       icon: 'fe fe-box',
//       // roles: ['employee'], // set user roles with access to this route

//       children: [
//         {
//           title: 'Overview',
//           key: 'operationsdashboard',
//           url: '/dashboard/operations',
//         },
//         {
//           title: 'Inbound',
//           key: 'inbounddashboard',
//           url: '/dashboard/inbound',
//         },
//         {
//           title: 'Outbound',
//           key: 'outbounddashboard',
//           url: '/dashboard/outbound',
//         },
//       ],
//     },

//     {
//       category: true,
//       title: 'Reports',
//     },

//     {
//       title: 'Operations',
//       key: 'operationsreports',
//       icon: 'fe fe-file',
//       // roles: ['employee'], // set user roles with access to this route

//       children: [
//         {
//           title: 'Inbound Summary',
//           key: 'reportsinboundsummary',
//           url: '/reports/inbound',
//         },
//         {
//           title: 'Outbound Summary',
//           key: 'reportsoutboundsummary',
//           url: '/reports/outbound',
//         },
//         {
//           title: 'Bin Utilization',
//           key: 'reportsbinutilization',
//           url: '/reports/binutilization',
//         },
//         {
//           title: 'Inbound SLA',
//           key: 'reportsinboundsla',
//           url: '/reports/inboundsla',
//         },
//         {
//           title: 'IBD SLA - Warehouse',
//           key: 'reportsinboundslaware',
//           url: '/reports/slawarehouse',
//         },
//         {
//           title: 'IBD SLA - Orders',
//           key: 'reportsinboundslaorders',
//           url: '/reports/inboundslaorders',
//         },
//         {
//           title: 'Outbound SLA',
//           key: 'reportsoutboundsla',
//           url: '/reports/outboundsla',
//         },
//         {
//           title: 'OBD SLA - Warehouse',
//           key: 'reportsoutboundslaware',
//           url: '/reports/obdslawarehouse',
//         },
//         {
//           title: 'OBD SLA - Orders',
//           key: 'reportsoutboundslaorders',
//           url: '/reports/obdslaorders',
//         },
//         {
//           title: 'Inbound TAT',
//           key: 'reportsinboundtat',
//           url: '/reports/inboundtat',
//         },
//         {
//           title: 'Outbound TAT',
//           key: 'reportsoutboundtat',
//           url: '/reports/outboundtat',
//         },
//         // {
//         //   title: 'Inbound Pending',
//         //   key: 'reportsoutboundpa',
//         //   url: '/reports/outboundpa',
//         // },
//         {
//           title: 'Pending Outbound Process',
//           key: 'reportsoutboundpa',
//           url: '/reports/outboundpa',
//         },
//         {
//           title: 'Pending Dispatch Delivery',
//           key: 'pendingdispatch',
//           url: '/reports/pendingdispatch',
//         },
//         // {
//         //   title: 'Outbound Wave pending',
//         //   key: 'reportsoutboundwavepending',
//         //   url: '/reports/outboundwavepending',
//         // },
//         // {
//         //   title: 'Outbound Dispatch pending',
//         //   key: 'reportsoutboundwavepending',
//         //   url: '/reports/outboundwavepending',
//         // },
//         // {
//         //   title: 'Inbound Orders',
//         //   key: 'reportsinboundorders',
//         //   url: '/reports/inbounddetail',
//         // },
//         // {
//         //   title: 'Outbound Orders',
//         //   key: 'reportsoutboundorders',
//         //   url: '/reports/outbounddetail',
//         // },
//         {
//           title: 'Handheld Utilization',
//           key: 'reportshht',
//           url: '/reports/hht',
//         },
//         {
//           title: 'Driver Utilization',
//           key: 'reportsdriver',
//           url: '/reports/driver',
//         },
//         {
//           title: 'Shipway Utilization',
//           key: 'reportshipway',
//           url: '/reports/shipway',
//         },
//         {
//           title: 'Stock Agening',
//           key: 'stockagening',
//           url: '/reports/stockagening',
//         },
//         {
//           title: 'Stock Comparison',
//           key: 'stockcomparison',
//           url: '/reports/stockcomparison',
//         },
//         {
//           title: 'Stock Enquiry',
//           key: 'stockenquiry',
//           url: '/reports/stockenquiry',
//         },
//         // {
//         //   title: 'Trip Details',
//         //   key: 'reportstripdetails',
//         //   url: '/reports/trips',
//         // },
//       ],
//     },
//     // {
//     //   title: 'Financial',
//     //   key: 'financereports',
//     //   icon: 'fe fe-file-text',
//     //   // roles: ['employee'], // set user roles with access to this route

//     //   children: [
//     //     {
//     //       title: 'BD Report',
//     //       key: 'reportsbdreport',
//     //       icon: 'fe fe-file',
//     //       url: '/reports/bdreport',
//     //     }
//     //   ],
//     // },
//     {
//       title: 'Integration',
//       key: 'integrationreports',
//       icon: 'fe fe-file-text',
//       // roles: ['employee'], // set user roles with access to this route

//       children: [
//         {
//           title: 'Exceptions Summary',
//           key: 'execptions',
//           icon: 'fe fe-file',
//           url: '/reports/exceptions',
//         },
//       ],
//     },
//     {
//       title: 'Transaction',
//       key: 'transactions',
//       icon: 'fe fe-help-circle',
//       // roles: ['employee'], // set user roles with access to this route

//       children: [
//         {
//           title: 'Enquiries',
//           key: 'enquiries',
//           icon: 'fe fe-book',
//           url: '/reports/enquiries',
//         },
//       ],
//     },
//   ]
// }
