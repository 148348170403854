import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
// import { login, currentAccount, logout } from 'services/firebase.auth.service'
import { getAuthToken } from 'services/gettoken'
import actions from './actions'

export function* LOGIN({ payload }) {
  localStorage.clear()
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // const success = yield call(login, email, password)

  try {
    const data = yield call(getAuthToken, 'key', email, password)
    if (data?.message === 'success') {
      localStorage.setItem('user', JSON.stringify(data?.json))
      localStorage.setItem('username', email)
      localStorage.setItem('token', data?.json?.token)

      yield history.push('/')
      notification.success({
        message: 'Logged In',
        description: 'You have successfully logged in to ConnectOne!',
      })
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      })
      window.location.reload()
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.error({
        message: 'Login Failed',
        description: 'Please enter correct username/password',
      })
    }
  } catch (e) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error',
      description: e.message,
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // const response = yield call(currentAccount)
  let user = localStorage.getItem('user')
  user = JSON.parse(user)
  const name = localStorage.getItem('username')
  if (user) {
    const { token } = user

    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: 1,
        name: 'Administrator',
        email: '',
        username: name,
        avatar: '',
        token,
        userData: user,
        role: 'admin',
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  // yield call(logout)
  localStorage.removeItem('user')
  localStorage.removeItem('token')
  localStorage.removeItem('username')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      token: '',
      authorized: false,
      loading: false,
    },
  })
  window.location.reload()
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
