import { put, takeEvery } from 'redux-saga/effects'
import { LOAD_EXCEPTIONS_ERROR, LOAD_EXCEPTIONS_LOADING, LOAD_EXCEPTIONS_SUCCESS } from './actions'
import Api from './api'

async function fetchAsync(func) {
  const response = await func()

  if (response.ok) {
    return await response.json()
  }

  throw new Error('Unexpected error!!!')
}

function* fetchException() {
  try {
    const exceptions = yield fetchAsync(Api.getException)

    yield put({ type: LOAD_EXCEPTIONS_SUCCESS, data: exceptions })
  } catch (e) {
    yield put({ type: LOAD_EXCEPTIONS_ERROR, error: e.message })
  }
}

export function* exceptionSaga() {
  yield takeEvery(LOAD_EXCEPTIONS_LOADING, fetchException)
}

export default exceptionSaga
