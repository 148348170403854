import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import exceptions from './exceptions/reducers'
import inboundOperations from './inboundoperations/reducers'
import outboundsOperations from './outboundOperations/reducers'
// import utilizations from './utilizations/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    exceptions,
    inboundOperations,
    outboundsOperations,
  })
