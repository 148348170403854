import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import exceptions from './exceptions/sagas'
import inboundOperations from './inboundoperations/sagas'
import outboundOperations from './outboundOperations/sagas'
import utilizations from './utilizations/sagas'



export default function* rootSaga() {
  yield all([user(), menu(), settings(),exceptions(),inboundOperations(),outboundOperations(),utilizations()])
}
