import { put, takeEvery } from 'redux-saga/effects'
import {
  LOAD_INBOUNDDATA_ERROR,
  LOAD_INBOUNDDATA_LOADING,
  LOAD_INBOUNDDATA_SUCCESS,
} from './actions'
import Api from './api'

async function fetchAsync(func) {
  const response = await func()

  if (response.ok) {
    return await response.json()
  }

  throw new Error('Unexpected error!!!')
}

function* fetchSummary() {
  try {
    const inData = yield fetchAsync(Api.getData)

    yield put({ type: LOAD_INBOUNDDATA_SUCCESS, data: inData })
  } catch (e) {
    yield put({ type: LOAD_INBOUNDDATA_ERROR, error: e.message })
  }
}

export function* exceptionSaga() {
  yield takeEvery(LOAD_INBOUNDDATA_LOADING, fetchSummary)
}

export default exceptionSaga
