import axios from 'axios'
import {
  BASE_UTILS_URL,
  BASE_COMMON_URL,
  BASE_APP_URL,
  BASE_URL,
  BASE_SECURITY_URL,
  BASE_BD_URL,
  BASE_MOBILE_URL,
  BASE_ENQUIRY_URL,
  BASE_ESHIP_URL,
  BASE_PODUPLOAD_URL,
} from 'config'

const token = localStorage.getItem('token')
const username = localStorage.getItem('username')
const axiosHeaders = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
}
// const myHeaders = new Headers()
// myHeaders.append('Authorization', `Bearer ${token}`)
// myHeaders.append('Content-Type', 'application/json')

export async function getLocation(key, { divisioncode }) {
  const res = await axios({
    baseURL: BASE_COMMON_URL,
    url: '/locations',
    params: { divisioncode },
    method: 'GET',
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export async function getLocationByCustomer(key, { clientcode }) {
  const res = await axios({
    baseURL: BASE_COMMON_URL,
    url: '/customerlocations',
    params: { clientcode },
    method: 'GET',
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export async function getDivision() {
  const res = await axios({
    baseURL: BASE_COMMON_URL,
    url: '/divisions',
    method: 'GET',
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export async function getAgency() {
  const res = await axios({
    baseURL: BASE_COMMON_URL,
    url: '/agency',
    method: 'GET',
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export async function getCustomers() {
  const res = await axios({
    baseURL: BASE_COMMON_URL,
    url: '/customers',
    method: 'GET',
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export async function getGroupLocations() {
  const res = await axios({
    url: '/grouplocations',
    baseURL: BASE_COMMON_URL,
    method: 'GET',
    headers: { ...axiosHeaders },
  })

  return res?.data?.json
}

export async function getIdbCustSummary(key, { clientcode, locationcode, fromdate, todate, type }) {
  const res = await axios({
    url: '/ibdcustomerwisesummary',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { clientcode, locationcode, fromdate, todate, type, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export async function getIdbWareSummary(key, { locationdesc, fromdate, todate, type }) {
  const res = await axios({
    url: '/ibdwarehousewisesummary',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { locationdesc, fromdate, todate, type, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export async function getOdbCusSummary(key, { clientcode, locationcode, fromdate, todate, type }) {
  const res = await axios({
    url: '/obdcustomerwisesummary',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { clientcode, locationcode, fromdate, todate, type, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export async function getOdbWareSummary(key, { locationdesc, fromdate, todate, type }) {
  const res = await axios({
    url: '/obdwarehousewisesummary',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { locationdesc, fromdate, todate, type, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export async function getIbdTatCustomerWise(
  key,
  { clientcode, locationcode, fromdate, todate, type },
) {
  const res = await axios({
    url: '/ibdtatcustomerwisesummary',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { clientcode, locationcode, fromdate, todate, type, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export async function getIdbTatWarehouseWise(key, { locationdesc, fromdate, todate }) {
  const res = await axios({
    url: '/ibdtatwarehousewisesummary',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { locationdesc, fromdate, todate, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getCustomerWise = async (
  url,
  { clientcode, locationcode, fromdate, todate, type, ...rest },
) => {
  const res = await axios({
    url,
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { clientcode, locationcode, fromdate, todate, type, ...rest, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getWareHouseWise = async (url, { locationdesc, fromdate, todate }) => {
  const res = await axios({
    url,
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { locationdesc, fromdate, todate, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getDetails = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getCommonApi = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_COMMON_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getSecurity = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_SECURITY_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getUtilities = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_UTILS_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getEship = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_ESHIP_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getPodUpload = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_PODUPLOAD_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getMonitoring = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getBDReports = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_BD_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getMobile = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_MOBILE_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getEnquiry = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_ENQUIRY_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getApplication = async (url, params) => {
  const res = await axios({
    url,
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { ...params, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getDriverUtilization = async (key, { date }) => {
  const res = await axios({
    url: 'driver/driverutilization',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { date, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getDriverProcessed = async (key, { date, locationcode, type = 'processed' }) => {
  const res = await axios({
    url: 'driver/mobileusagedetails',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { date, username, locationcode, type },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getDriverActual = async (key, { date, locationcode, type = 'actual' }) => {
  const res = await axios({
    url: 'driver/mobileusagelatlongdetails',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { date, username, locationcode, type },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getDriverInvoiceDetails = async (key, { tripid }) => {
  const res = await axios({
    url: 'driver/mobileusageinvoicedetails',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { username, tripid },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getDriverTripDetails = async (key, { tripid }) => {
  const res = await axios({
    url: 'driver/mobileusagetripdetails',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { username, tripid },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getHHTUtilization = async (key, { fromdate, todate }) => {
  const res = await axios({
    url: '/hht/hhtutilization',
    baseURL: BASE_APP_URL,
    method: 'GET',
    params: { fromdate, todate, username },
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const getReportTypes = async () => {
  const res = await axios({
    baseURL: BASE_COMMON_URL,
    url: '/obdreporttypes',
    method: 'GET',
    headers: { ...axiosHeaders },
  })
  return res.data?.json
}

export const postDispatch = async (data = {}) => {
  const res = await axios({
    baseURL: BASE_UTILS_URL,
    url: 'dispatchrepush/transactions',
    method: 'POST',
    headers: { ...axiosHeaders },
    data,
  })
  return res.data?.json
}

export const postDelivery = async (data = {}) => {
  const res = await axios({
    baseURL: BASE_UTILS_URL,
    url: 'deliveryrepush/transactions',
    method: 'POST',
    headers: { ...axiosHeaders },
    data,
  })
  return res.data?.json
}

export const postTMS = async ({ url = '', data = {} }) => {
  const res = await axios({
    baseURL: BASE_UTILS_URL,
    url: url,
    method: 'POST',
    headers: { ...axiosHeaders },
    data,
  })
  return res.data?.json
}

export const repushTMS = async (data = {}) => {
  const res = await axios({
    baseURL: BASE_UTILS_URL,
    url: 'tmsvssap/repushpod',
    method: 'POST',
    headers: { ...axiosHeaders },
    data,
  })
  return res.data?.json
}

export const repushEshipService = async (data = {}) => {
  const res = await axios({
    baseURL: BASE_ESHIP_URL,
    url: 'Eship/ServiceRepush',
    method: 'POST',
    headers: { ...axiosHeaders },
    data,
  })
  return res.data?.json
}
export const repushEshipShipment = async (data = {}) => {
  const res = await axios({
    baseURL: BASE_ESHIP_URL,
    url: 'Eship/Eshiprepush',
    method: 'POST',
    headers: { ...axiosHeaders },
    data,
  })
  return res.data?.json
}

export const uploadFileandPod = async ({ data = {}, files = [] }) => {
  const formData = new FormData()
  // eslint-disable-next-line no-unused-expressions
  files?.forEach(item => {
    formData.append('files', item)
  })

  const res = await Promise.all([
    axios({
      baseURL: BASE_PODUPLOAD_URL,
      url: 'podupload/transactions',
      method: 'POST',
      headers: { ...axiosHeaders },
      data,
    }),
    axios({
      baseURL: BASE_PODUPLOAD_URL,
      url: 'file/Upload',
      method: 'POST',
      headers: { ...axiosHeaders, 'Content-Type': 'multipart/form-data' },
      data: formData,
    }),
  ])
  return res
}

export const getInvoiceFile = async data => {
  const res = await axios({
    baseURL: BASE_PODUPLOAD_URL,
    url: 'podupload/filedownlaod',
    method: 'GET',
    headers: { ...axiosHeaders },
    params: data,
  })
  return res.data?.json?.[0]?.filebytes
}

export const fileDownload = (url, filename) => {
  fetch(url)
    .then(res => res.blob())
    .then(fblob => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(fblob)
      link.download = filename
      link.click()
      link.remove()
    })
}

export const postOBDupload = async (data = {}) => {
  const res = await axios({
    baseURL: BASE_UTILS_URL,
    url: 'obdtask/transactions',
    method: 'POST',
    headers: { ...axiosHeaders },
    data,
  })
  return res.data?.json
}

export const postOBDTask = async (data = {}) => {
  const res = await axios({
    baseURL: BASE_UTILS_URL,
    url: 'obdtask/createtask',
    method: 'POST',
    headers: { ...axiosHeaders },
    data,
  })
  return res.data?.json
}
