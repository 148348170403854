import { useEffect } from 'react'
import NProgress from 'nprogress'

export function Loader() {
  useEffect(() => {
    NProgress.start()
    return () => NProgress.done()
  }, [])
  return null
}
