import { DATE_FORMAT } from 'config'
import { useQuery } from 'react-query'
import moment from 'moment'
import {
  getLocationByCustomer,
  getCustomers,
  getGroupLocations,
  getCustomerWise,
  getWareHouseWise,
  getDetails,
  getDriverUtilization,
  getHHTUtilization,
  getReportTypes,
  getCommonApi,
  getUtilities,
  getMonitoring,
  getApplication,
  getSecurity,
  getBDReports,
  getEnquiry,
  getMobile,
  getEship,
  getPodUpload,
} from './queries'

const commonConfig = {
  initialData: [],
  initialStale: true,
}

export const useCustomers = (config = {}) => {
  const res = useQuery('customers', getCustomers, {
    ...commonConfig,
    ...config,
  })
  return res
}

export const useLocationsByCus = (clientcode, config = {}) => {
  const res = useQuery(['customers-locations', { clientcode }], getLocationByCustomer, {
    ...commonConfig,
    ...config,
  })
  return res
}

export const useWarehouse = (config = {}) => {
  const res = useQuery(['warehouse-locations'], getGroupLocations, {
    ...commonConfig,
    ...config,
  })
  return res
}

export const useReportTypes = (config = {}) => {
  const res = useQuery(['report-types'], getReportTypes, {
    ...commonConfig,
    ...config,
  })
  return res
}

export const useCustomerWise = (url, input = {}, config = {}) => {
  const res = useQuery([url, { ...input }], getCustomerWise, {
    ...commonConfig,
    ...config,
  })
  return res
}

export const useWareHouseWise = (url, input = {}, config = {}) => {
  const res = useQuery([url, { ...input }], getWareHouseWise, {
    ...commonConfig,
    ...config,
  })
  return res
}

export const useDetails = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getDetails, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const useCommonApi = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getCommonApi, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const useSecurity = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getSecurity, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const useUtilities = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getUtilities, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const useEship = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getEship, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const usePODUpload = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getPodUpload, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const useMonitoring = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getMonitoring, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const useEnquiry = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getEnquiry, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const useBDReport = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getBDReports, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const useMobile = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getMobile, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const useApplication = (queryStr = {}, config = {}) => {
  const { url, ...params } = queryStr
  const res = useQuery([url, { ...params }], getApplication, {
    enabled: !!url,
    ...commonConfig,
    ...config,
  })
  return res
}

export const useDriverUtilization = ({ date = moment().format(DATE_FORMAT) }, config = {}) => {
  const res = useQuery(['driver-utilization', { date }], getDriverUtilization, {
    ...commonConfig,
    ...config,
  })
  return res
}

export const useHHTUtilization = (
  { fromdate = moment().format(DATE_FORMAT), todate = moment().format(DATE_FORMAT) },
  config = {},
) => {
  const res = useQuery(['hht-utilization', { fromdate, todate }], getHHTUtilization, {
    ...commonConfig,
    ...config,
  })
  return res
}
