import React, { useState } from 'react'
// import classNames from 'classnames'
// import ChatComponent from './ChatComponent'
import style from './style.module.scss'

const SupportChat = () => {
  const [isSupportChatOpen, setSupportChatOpen] = useState(false)
  const toggleSupportChat = () => {
    if (!isSupportChatOpen) {
      window.botpressWebChat.sendEvent({ type: 'show' })
    } else {
      window.botpressWebChat.sendEvent({ type: 'hide' })
    }
    setSupportChatOpen(state => !state)
  }

  return (
    <div className={style.chat}>
      <button onClick={toggleSupportChat} type="button" className={style.toggleButton}>
        <i className={`${style.icon} fe fe-message-square mr-md-2`} />
        <span className="d-none d-md-inline">Support Chat</span>
      </button>
    </div>
  )
}

export default SupportChat
